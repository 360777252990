.es-alert-container{
	max-width: 306px;
	padding: 3px;
	position: fixed;
	z-index: 10000;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	
	
	&.top{
		top: 60px;
		justify-content: flex-start;
	}
	&.bottom{
		bottom: 50px;
		justify-content: flex-end;
	}
	&.right{
		right: 30px;
	}
	&.left{
		left: 30px;
	}
	&.center{
		right: calc(50% - 150px);
	}
	.es-alert{
		
		transition: opacity 250ms ease 0s;
		opacity: 1;
		
		
		/*background-color: rgb(21, 21, 21);*/
		background-color: #151515a6;
		
		color: #FFF;
		padding: 2px;
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: Arial;
		box-sizing: border-box;
		/* box-shadow: 2px 2px 2px 1px #303030; */
		margin-bottom: 10px;
		width: 300px;
		position: relative;
		
		.es-alert-icon{
			cursor: pointer;
			font-size: 24px;
			padding: 10px;
			display: flex;
			align-items: center;
			text-align: center;
		}
		.es-alert-msg{
			padding: 10px;
			font-size: 15px;
			display: inline-block;
			width: 260px;
		}
		.es-alert-close{
			cursor: pointer;
			padding: 10px;
			font-size: 16px;
			display: inline-block;
			width: 40px;
			text-align: center;
		}
	}
}
