
@import './styles/slide_transitions.scss';

.App-link {
  color: #61dafb;
}

/* :root{
  --amplify-primary-color: green;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
} */