/*
    Routing transition effect:
*/
$slideTransitionTime: 300ms;
.slide-forward-enter, .slide-forward-exit{
    transition: transform $slideTransitionTime ease-out;
}
.slide-forward-enter{
    transform: translateX(100%);
    -webkit-box-shadow: -1px 0px 1px -1px rgba(0,0,0,0.34);
    -moz-box-shadow: -1px 0px 1px -1px rgba(0,0,0,0.34);
    box-shadow: -1px 0px 1px -1px rgba(0,0,0,0.34);
    &.slide-forward-enter-active{
        transform: translateX(0%);
    }
}
.slide-forward-exit{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(0%);
    z-index: -1;
}
.slide-forward-exit-active{
    transform: translateX(0%);
}

.slide-backward-enter, .slide-backward-exit{
    transition: transform $slideTransitionTime ease-out;
}
.slide-backward-enter{
    transform: translateX(-100%);
    &.slide-backward-enter-active{
        transform: translateX(0%);
    }
}
.slide-backward-exit{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(0%);
    -webkit-box-shadow: -1px 0px 1px -1px rgba(0,0,0,0.34);
    -moz-box-shadow: -1px 0px 1px -1px rgba(0,0,0,0.34);
    box-shadow: -1px 0px 1px -1px rgba(0,0,0,0.34);
}
.slide-backward-exit-active{
    transform: translateX(100%);
}