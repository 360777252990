.button{
    &.is-primary{
        background: #007CBA;
        border-radius: 4px;
        color: #FFF;
        &:hover{
            background: #0090D9;
        }
        &:active{
            background: #00699E;
        }
    }
    &.is-warning{
        background: rgba(230,136,66, 0.2); //#E68842;
        border-radius: 4px;
        color: #E46100;
        &:hover{
            color: #E46100;
            background: rgba(230,136,66, 0.4);
        }
        &:active{
            color: #E46100;
            background: rgba(230,136,66, 0.6);
        }
    }
    &.is-secondary{
        border: 1px solid #007CBA;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #FFF;
        color: #007CBA;
        &:hover{
            background: #EBF5FA;
        }
        &:active{
            background: #007CBA;
        }
    }

    &:disabled, &.is-disabled{
        background: #F0F2F3;
        color: #ABB1B9;
    }
}