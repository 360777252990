@import './variables.scss';
@import './button.scss';

.has-background-light{
    background-color: $light !important;
}
.has-text-primary{
    color: $primary !important;
}
.has-text-secondary{
    color: $secondary !important;
}
.button{
    &.is-text.no-decoration{
        text-decoration: none;
    }
    &.is-primary{
        background-color: $button-color;
        &:hover{
            background: $button-color-hover;
        }
        &.is-disabled, &:disabled{
            background: #F0F2F3;
            color: #ABB1B9;
        }
    }
    border-radius: 4px;
    padding: 12px 61px 13px 61px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 20px;
    &.is-medium{
        font-size: 20px;
    }
    &.is-rounded{
        border-radius: 28px;
    }
    &.is-text{
        background-color: transparent;
        color: $button-color;
        text-decoration: none;
    }
}
.hero{
    &.is-secondary{
        background-color: $secondary;
        .subtitle{
            color: $white;
        }
    }
}
.mt-1{
    margin-top: 1rem !important;
}
.my-1{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.mx-1{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.mb-1{
    margin-bottom: 1rem !important;
}
.px-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.px-1{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.py-0{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.py-1{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.p-1{
    padding: 1rem !important;
}
.pt-1{
    padding-top: 1rem !important;
}
.b-n{
    border: none !important;
}

/*
    General styling
*/
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
}
.list{
    border-radius: 0;
    a.list-item{
        background-color: #FFF;
    }
}
html, body, .fullheight{
    min-height: 100%;
    height: 100%;
}
.fullheight-body{
    min-height: calc(100% - 84px);
    height: calc(100% - 84px);
}
.has-vcentered{
    align-items: center;
}
.has-centered{
    justify-content: center;
}
.is-valign-middle{
    vertical-align: middle !important;
}
::placeholder {
    color: #ccc !important;
    opacity: 1;
}
:-moz-placeholder,
::-webkit-input-placeholder,
:-ms-input-placeholder, 
::-ms-input-placeholder { /* Internet Explorer 10-11, Microsoft Edge */
    color: #ccc !important;
}